import Vue from 'vue'
import Router from 'vue-router'
// import axios from 'axios'

Vue.use(Router)
let router = new Router({
    mode: 'history',
    routes: [{
            path: '/',
            name: 'landingPage',
            props: true,
            component: () =>
                import ('./components/LandingPage/landingPage'),
            meta: {
                requiresAuth: false,

            },
        },
        {
            path: '*',
            name: '404PageNotFound',
            props: true,
            component: () =>
                import ('./components/Common/404PageNotFound'),
            meta: {
                requiresAuth: false,

            }
        },
    ],
    scrollBehavior() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    },
})
export default router