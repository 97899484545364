<template>
  <div>
    <ServerError v-if="ServerError" />
    <v-app>
      <!-- <v-snackbar
        v-model="showSnackBar"
        color="#7CB9E8"
        right
        :timeout="timeout"
      >
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar> -->

      <AppHeader
      />
      <router-view  />
      <AppFooter
      />
    </v-app>
  </div>
</template>

<script>
import AppHeader from "./components/Common/appHeader";
import AppFooter from "./components/Common/appFooter";
// import axios from "axios";
import "./assets/styles/fonts.css";
import "./assets/styles/appStyle.css";
export default {
  name: "App",

  components: {
    AppHeader,
    AppFooter,
  },

  data: () => ({
    //
    appLoading: false,
    ServerError: false,
    showSnackBar: false,
    msg: null,
    timeout: 5000,
    sideNav: true,
  }),
  methods: {
  },
};
</script>
