<template>
  <div id="appNavbar">
    <ServerError v-if="ServerError" />
    <v-app-bar dense flat color="#FFFFFF" height="70px">
      <v-layout wrap justify-start>
        <v-flex xs12 sm12 md12 pr-16>
          <v-layout wrap justify-start>
            <v-flex xs12 sm4 md5 lg4 xl2 pr-16>
              <v-layout wrap justify-start>
                <v-img
                  src="./../../assets/newlogo.png"
                  contain
                  height="32px"
                  width="0px"
                ></v-img>
                <!-- <v-btn
                  elevation="0"
                  class="rounded-lg"
                  small
                  color="#353536FA"
                  dark
                  ><span style="font-family: poppinssemibold; letter-spacing: 0.5px; font-size: 10px;"
                    >UNDER DEVELOPMENT</span
                  ></v-btn
                > -->
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-app-bar>
  </div>
</template>
<script>
// import AccountMenu from "./accountMenu";
export default {
  components: {
    // AccountMenu,
  },
  data() {
    return {
      hover: false,
      appLoading: false,
      ServerError: false,
      sideNav: false,
      sideNav2: false,
      sideNav3: false,
      user: {},
      userName: null,
      profilePic: null,
      cartItems: 0,
      disabledNav: true,
      searchKey: null,
      myName: [],
    };
  },
};
</script>

<style>
input::-webkit-input-placeholder {
  color: #c5c5c5 !important;
  font-size: 14px !important;
  font-family: poppinsregular !important;
}

input::-moz-placeholder {
  color: #c5c5c5 !important;
  font-size: 14px !important;
  font-family: poppinsregular !important;
}

.searchBox .v-input__control {
  min-height: 10px !important;
}

.searchBox .v-input__control .v-input__slot {
  padding: 0px !important;
}

.v-application--is-ltr
  .v-toolbar__content
  > .v-btn.v-btn--icon:first-child
  + .v-toolbar__title,
.v-application--is-ltr
  .v-toolbar__extension
  > .v-btn.v-btn--icon:first-child
  + .v-toolbar__title {
  padding-left: 0px !important;
}

.bg1 {
  background: linear-gradient(174.14deg, #edf1fd 8.2%, #96cdf7 76.78%);
}
</style>
