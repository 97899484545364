import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from 'axios'
import ServerError from './components/Common/500ServerError'
Vue.component('ServerError', ServerError)


Vue.config.productionTip = false
window.axios = require('axios')

// axios.defaults.baseURL = 'http://192.168.52.126:5000';
// Vue.prototype.baseURL = "http://192.168.52.126:5000"
// Vue.prototype.mediaURL = "http://192.168.52.126:5000/wp?key="
// Vue.prototype.mediaUURL = "http://192.168.52.126:5000/file/get?key="

axios.defaults.baseURL = 'https://ecol-be.actecosys.com';
Vue.prototype.baseURL = "https://ecol-be.actecosys.com"
Vue.prototype.mediaURL = "https://ecol-be.actecosys.com/wp?key="
Vue.prototype.mediaUURL = "https://ecol-be.actecosys.com/file/get?key="

new Vue({
    vuetify,
    router,
    render: h => h(App)
}).$mount('#app')