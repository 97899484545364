<template>
  <div style="background: #044932">
    <!-- <v-img src="./../../assets/images/footer.png"> -->
    <v-layout wrap justify-center pt-12 pb-10>
      <v-flex xs10>
        <v-layout wrap justify-center>
          <v-flex xs12>
            <v-layout wrap justify-center pb-5>
              <v-flex xs6 sm4 md2 lg2>
                <v-img src="./../../assets/whitelogo.png" contain></v-img>
              </v-flex>
            </v-layout>
            <!-- <v-layout wrap justify-center>
              <v-flex xs12 sm12 md9 lg7>
                <span class="footerText"
                  >Science warns we have until the decade's end to reduce
                  emissions and protect 30-50% of land and sea. Current trends
                  point to a 2.9°C rise and worsening biodiversity loss. Without
                  global cooperation and major economic shifts, this crisis will
                  only escalate.</span
                >
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center pt-3 pb-8>
              <v-flex xs6 sm6 md6 lg6 align-self-center text-center>
                <v-layout wrap justify-center>
                  <v-flex xs3 sm2 md1 v-for="(item, i) in items" :key="i" text-center
                    ><v-img :src="item.img" width="25px" height="25px"></v-img
                  ></v-flex>
                </v-layout>
              </v-flex>
            </v-layout> -->
            <hr>
            <v-layout wrap justify-center pt-6>
              <v-flex xs12 sm6 md6 lg6 align-self-center text-center>
                <span style="font-family: poppinsregular; color: #fff; font-size: 13px;">© Copyright 2024, All rights reserved.</span>
                </v-flex>
                </v-layout>
            <!-- <v-divider style="color: white;"></v-divider> -->
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <!-- </v-img> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      items: [
        { img: require("./../../assets/fb.png") },
        { img: require("./../../assets/insta.png") },
        { img: require("./../../assets/x.png") },
      ],
    };
  },
};
</script>
<style>
.footerText {
  font-family: OutfitRegular;
  color: #ffffff;
  font-size: 14px;
}
</style>